export default {
  good: {
    en: "Good",
    ja: "良い"
  },
  notCut: {
    en: "Not cut",
    ja: "切らない"
  },
  notBlurry: {
    en: "Not blurry",
    ja: "ぼやけていない"
  },
  notReflective: {
    en: "Not reflective",
    ja: "反射しない"
  }
}
