import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.43734 14.1666H10.6873V9.16663H9.43734V14.1666ZM9.99984 7.62496C10.1943 7.62496 10.3575 7.56246 10.4894 7.43746C10.6214 7.31246 10.6873 7.15274 10.6873 6.95829C10.6873 6.76385 10.6214 6.59718 10.4894 6.45829C10.3575 6.3194 10.1943 6.24996 9.99984 6.24996C9.80539 6.24996 9.6422 6.3194 9.51025 6.45829C9.37831 6.59718 9.31234 6.76385 9.31234 6.95829C9.31234 7.15274 9.37831 7.31246 9.51025 7.43746C9.6422 7.56246 9.80539 7.62496 9.99984 7.62496V7.62496ZM9.99984 18.3333C8.86095 18.3333 7.78456 18.1145 6.77067 17.677C5.75678 17.2395 4.87136 16.6423 4.11442 15.8854C3.35748 15.1284 2.76025 14.243 2.32275 13.2291C1.88525 12.2152 1.6665 11.1319 1.6665 9.97913C1.6665 8.84024 1.88525 7.76385 2.32275 6.74996C2.76025 5.73607 3.35748 4.85413 4.11442 4.10413C4.87136 3.35413 5.75678 2.76038 6.77067 2.32288C7.78456 1.88538 8.86789 1.66663 10.0207 1.66663C11.1596 1.66663 12.2359 1.88538 13.2498 2.32288C14.2637 2.76038 15.1457 3.35413 15.8957 4.10413C16.6457 4.85413 17.2394 5.73607 17.6769 6.74996C18.1144 7.76385 18.3332 8.84718 18.3332 9.99996C18.3332 11.1388 18.1144 12.2152 17.6769 13.2291C17.2394 14.243 16.6457 15.1284 15.8957 15.8854C15.1457 16.6423 14.2637 17.2395 13.2498 17.677C12.2359 18.1145 11.1526 18.3333 9.99984 18.3333Z"
        fill="white"/>
    </svg>
  );
};
